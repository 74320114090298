<template>
  <div class="brainfit">
    <swiper :options="swiperOption" class="swiperBlock flexItem110">
      <swiper-item class="flexColStartRowCenter" v-for="(item,index) in banner" :key="index">
        <div class="swiper-item__inner" :style="{backgroundImage:'url('+item.img+')'}"></div>
      </swiper-item>
    </swiper>
    <image-box :src="require('../../assets/image/brainfit/brainfit_1.jpg')" :height="728"></image-box>
    <image-box :src="require('../../assets/image/brainfit/brainfit_2.jpg')" :height="700"></image-box>
    <image-box :src="require('../../assets/image/brainfit/brainfit_3.jpg')" :height="1415"></image-box>
    <image-box :src="require('../../assets/image/brainfit/brainfit_4.jpg')" :height="1048"></image-box>
    <image-box :src="require('../../assets/image/brainfit/brainfit_5.jpg')" :height="1165"></image-box>
    <image-box :src="require('../../assets/image/brainfit/brainfit_6.jpg')" :height="726"></image-box>
    <image-box :src="require('../../assets/image/brainfit/brainfit_7.jpg')" :height="1098"></image-box>
    <image-box :src="require('../../assets/image/brainfit/brainfit_8.jpg')" :height="1407"></image-box>
  </div>
</template>

<script>
import ImageBox from "../../components/ImageBox/ImageBox";
import {swiper, swiperItem} from "../../components/swiper";
export default {
  name: "about",
  components: {
    "swiper": swiper,
    "swiper-item": swiperItem,
    "image-box":ImageBox
  },
  data() {
    return {
      swiperOption: {
        width:"100%",
        height:"598px",
        pageBtn: true,
        callback: () => {
        },
        pagination: {
          show: true,
          canClick:true
        },
        current: 0,
        itemsNum: 2,
        loop: true,
        loopTime: 5000,
        auto: true
      },
      banner: [
        {
          img: require("../../assets/image/brainfit/brainfit_banner_1.jpg")
        },
        {
          img: require("../../assets/image/brainfit/brainfit_banner_2.jpg")
        },
      ]
    }
  },
  created() {
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
@media screen and (min-width: $minDeviceWidth_pc) {
  @import "brainfit_pc";
}

@media screen and (max-width: $minDeviceWidth_pc) {
  @import "brainfit_mobile";
}
</style>
